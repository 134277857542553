// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-changeinfo-jsx": () => import("./../../../src/pages/changeinfo.jsx" /* webpackChunkName: "component---src-pages-changeinfo-jsx" */),
  "component---src-pages-contract-jsx": () => import("./../../../src/pages/contract.jsx" /* webpackChunkName: "component---src-pages-contract-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-not-allow-jsx": () => import("./../../../src/pages/notAllow.jsx" /* webpackChunkName: "component---src-pages-not-allow-jsx" */),
  "component---src-pages-price-jsx": () => import("./../../../src/pages/price.jsx" /* webpackChunkName: "component---src-pages-price-jsx" */),
  "component---src-pages-privacy-jsx": () => import("./../../../src/pages/privacy.jsx" /* webpackChunkName: "component---src-pages-privacy-jsx" */),
  "component---src-pages-process-jsx": () => import("./../../../src/pages/process.jsx" /* webpackChunkName: "component---src-pages-process-jsx" */)
}

